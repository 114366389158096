<template>


	<v-col cols="12" sm="12" md="8" lg="6" xl="6" class="mx-auto">
		<v-card class="glassmorphism pa-4" :class="$vuetify.theme.dark ? 'dark' : 'light'">

			<v-col cols="12" class="mx-auto">
				<v-row no-gutters>
					<v-col cols="12">
						<Title weight="regular" titleSize="h2" titleColor="white">Créer</Title>
					</v-col>

					<v-col cols="12">
						<v-text-field dark class="pt-4 mx-auto" label="Coller le lien ici" v-model="qrURL" type="url"
							style="max-width: 960px;"></v-text-field>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="6" class="mx-auto">

				<v-card-title>
					<v-spacer></v-spacer>
					<vue-qrcode
						height="150"
						:value="qrURL"
						v-if="qrURL" />
					<v-spacer></v-spacer>
				</v-card-title>

			</v-col>

			<v-col cols="12">
				<v-row no-gutters>
					<v-col cols="12">
						<Title
							weight="regular"
							titleSize="h2"
							titleColor="white"
						>Sauvegarder</Title>
					</v-col>

					<v-col cols="12" class="mx-auto pt-6">

						<v-card-title>
							<v-spacer></v-spacer>
							<v-btn color="white" outlined @click="saveAs('perso')" class="mx-auto">
								<Title
									weight="regular"
									titleColor="white"
								>Ajouter à mon espace personnel</Title>
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-title>

						<v-card-title>
							<v-spacer></v-spacer>
							<v-btn outlined color="white" class="mx-auto" @click="saveAs('company')">
								<Title weight="regular">
									En tant que l'entreprise {{ compData.name }}
								</Title>
							</v-btn>
							<v-spacer></v-spacer>
						</v-card-title>

					</v-col>
				</v-row>
			</v-col>
		</v-card>

	</v-col>


</template>


<script>
	import VueQrcode from 'vue-qrcode';
	import Title from '@/components/graphics/title';

	export default {
		components: {
			Title,
			VueQrcode
		},
		name: 'GenQRCode',
		data() {
			return {
				qrURL: '',
				actuURL: window.location.href,

				userInfo: this.$models.user,
				compData: this.$models.company,
			};
		},
		watch: {
			$route: function () {
				this.actuURL = window.location.href
			}
		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(doc.data().company).onSnapshot(doc => {
					this.compData = {
						...doc.data(),
						id: doc.id
					};
				});
			});
		},
		methods: {
			saveAs(entity) {
				if (!this.qrURL) return this.$store.dispatch('setSnackbar', {
					text: `Vous n'avez pas mentionné l'URL !`,
					color: 'error'
				});

				if (entity == 'company') {
					this.$db.collection("companies").doc(this.compData.id).collection("qrcodes").add({
						'url': this.qrURL,
						'creationDate': new Date
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Votre QR code a été enregistré !`,
						});
					});
				} else if (entity == 'perso') {
					this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).collection("qrcodes").add({
						'url': this.qrURL,
						'creationDate': new Date
					}).then(() => {
						this.$store.dispatch('setSnackbar', {
							text: `Votre QR code a été enregistré !`,
						});
					});
				}
			}
		}
	}
</script>