<template>
	<div class="qrgen">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-row>



			<v-col cols="12">

				<Gen_qr class="pa-5" />

			</v-col>

			<v-sheet height="750px"></v-sheet>




			<v-col cols="12" sm="12" md="12" lg="10" xl="8" class="mx-auto">

				<v-row>
					<v-col cols="12" md="6">
						<v-card elevation="1">
							<Title class="pl-3 py-4" weight="regular" titleSize="h5">
								QR Codes de l'entreprise {{ compData.name }}
							</Title>
							<v-card v-for="qrcode in compQRCodes" :key="qrcode.id" class="ma-3 mb-10" tile elevation="0">
								<QRCode :qrcode='qrcode' :userInfo='userInfo' type="company" />
							</v-card>
						</v-card>
					</v-col>

					<v-col cols="12" md="6">
						<v-card elevation="1">
						<Title class="pl-3 py-4" weight="regular">
							QR Codes personnels :
						</Title>
						<v-card tile elevation="0" v-for="qrcode in userQRCodes" :key="qrcode.id" class="ma-3 mb-10">
							<QRCode :qrcode='qrcode' :userInfo='userInfo' type="perso" />
						</v-card>
						</v-card>
					</v-col>

					<v-sheet height="300px"></v-sheet>
				</v-row>
			</v-col>


		</v-row>
	</div>
</template>

<script>
	import Gen_qr from '@/components/modules/@tools/Gen_qr';
	import QRCode from '@/components/modules/@tools/QRCode';
	import Head from '@/components/graphics/head';
	import Title from '@/components/graphics/title';

	export default {
		head: {
			title: {
				inner: "Génération QR Code"
			}
		},
		components: {
			Head,
			Title,
			Gen_qr,
			QRCode
		},
		name: 'GenQRCode',
		data() {
			return {
				Head: {

					Title: 'QR Code',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "95vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},

				compQRCodes: [],
				userQRCodes: [],

				compData: this.$models.company,
				userInfo: this.$models.user
			};
		},
		methods: {

		},
		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = {
					...doc.data(),
					id: doc.id
				};

				this.$db.collection("companies").doc(doc.data().company).onSnapshot(doc => {
					this.compData = {
						...doc.data(),
						id: doc.id
					};
				});

				this.$db.collection("companies").doc(doc.data().company).collection("qrcodes").onSnapshot((
					res) => {
					this.compQRCodes = [];

					res.forEach((document) => {
						this.compQRCodes.push({
							...document.data(),
							id: document.id
						});
					});
				});

				this.$db.collection("users").doc(doc.id).collection("qrcodes").onSnapshot((res) => {
					this.userQRCodes = [];

					res.forEach((document) => {
						this.userQRCodes.push({
							...document.data(),
							id: document.id
						});
					});
				});
			});
		}
	}
</script>