<template>
	<div>
		<v-card min-height="225px">
			<v-btn style="position: absolute; top: 5px; right: 0px;" color="error" text fab small class="mr-2" @click="deleteQR(qrcode, type)">
				<v-icon>mdi-delete</v-icon>
			</v-btn>

			<v-card-title>
				<span class="overline">{{ qrcode.url.split('/', 3)[2] }}</span>
			</v-card-title>
			<v-row class="px-5">

				<v-col cols="12" sm="8">
					<h4>URL :</h4>
					<a :href="qrcode.url" target="_blank">{{qrcode.url}}</a>

					<h4 class="mt-4">Création:</h4>
					<p>Le {{ $functions.getDateFormat(qrcode.creationDate) }}</p>
				</v-col>

				<v-divider vertical v-if="$vuetify.breakpoint.smAndUp"></v-divider>

				<v-col cols="12" sm="4" class="pa-0">
					<v-card elevation="6" width="148px" height="148px" class="pa-0 mb-2 mx-auto">
						<vue-qrcode class="ma-0" :value="qrcode.url" />
					</v-card>
				</v-col>

			</v-row>
		</v-card>
	</div>
</template>


<script>
	import VueQrcode from 'vue-qrcode';

	export default {
		props: {
			'qrcode': {
				type: Object
			},
			'userInfo': {
				type: Object
			},
			'type': {
				type: String
			}
		},
		components: {
			VueQrcode
		},
		data() {
			return {}
		},

		methods: {
			deleteQR(qr, as, path = '') {
				switch (as) {
					case "company":
						path = "companies/" + this.userInfo.company + "/qrcodes";
						break;
					case "perso":
						path = "users/" + this.userInfo.id + "/qrcodes";
						break
				}

				this.$katana.removeAllDataFromDocument(this.$db.collection(path).doc(qr.id), `Le QR Code à l'adresse ${qr.url} à été supprimé !`);
			}
		},
	}
</script>